<template>
  <div class="p-2">
    <div class="d-flex flex-row cont">
      <div v-if="list_users" class="cont">
        <div
          v-for="(item, idx) in list_users"
          :key="idx + 'list'"
          :class="[select_user === item.id ? 'selected' : '', 'cursor pl-2']"
          @click.prevent="get_one_user(item.id)"
        >
          {{ item.user }}
        </div>
      </div>
      <div v-if="data_select_user" class=" ml-2">
        <div v-if="data_select_user">
          <button
            v-for="(item2, idx2) in data_select_user"
            :key="idx2 + 'dirs'"
            type="button"
            :class="[
              select_folder_year_or_report === item2.value ? 'active' : '',
              'btn btn-light shadow-none m-1'
            ]"
            @click="selectYearOrReport(item2.value)"
          >
            {{ item2.title }}
          </button>
        </div>
        <div v-if="data_select_folder_year_or_report" class="content">
          <button
            v-for="(item3, idx3) in data_select_folder_year_or_report"
            :key="idx3 + 'qq'"
            class="m-1 btn btn-sm btn-light shadow-none"
          >
            {{ item3.title }}
          </button>
        </div>
      </div>
    </div>
    <Loader v-if="loader" />
  </div>
</template>
<script>
import { HTTPGET } from "@/api";
import Loader from "@/components/Loader.vue";

export default {
  name: "FileManager",
  components: {
    Loader
  },
  data: function() {
    return {
      loader: false,
      list_users: null,
      select_user: null,
      data_select_user: null,
      data_select_folder_year_or_report: null,
      select_folder_year_or_report: null
    };
  },
  mounted() {
    this.get_list_users();
  },
  methods: {
    get_list_users() {
      this.loader = true;
      HTTPGET({
        url: "/admin/file_manager/get_data.php"
      })
        .then(response => (this.list_users = response.data.list_users))
        .then(() => (this.loader = false));
    },
    get_one_user(id) {
      this.loader = true;
      this.select_user = id;
      this.select_folder_year_or_report = null;
      this.data_select_folder_year_or_report = null;
      HTTPGET({
        url: "/admin/file_manager/get_data_one_user.php",
        params: {
          user: id
        }
      })
        .then(response => (this.data_select_user = response.data.dirs))
        .then(() => (this.loader = false));
    },
    selectYearOrReport(id) {
      this.loader = true;
      this.select_folder_year_or_report = id;
      HTTPGET({
        url: "/admin/file_manager/get_data_year_or_report.php",
        params: {
          folder: id,
          user: this.select_user
        }
      })
        .then(
          response =>
            (this.data_select_folder_year_or_report = response.data.dirs)
        )
        .then(() => (this.loader = false));
    }
  }
};
</script>
<style scoped>
.cont {
  max-height: 90vh;
  min-width: 20%;
  overflow: auto;
}
.selected {
  background: #007bff;
  color: #fff;
}
.cursor:hover {
  cursor: pointer;
  background: #007bff;
  color: #fff;
}
.content {
  max-height: 85vh;
  overflow: auto;
}
</style>
